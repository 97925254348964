require("./components/mobile-menu.js");
require("./components/header");
require("./components/to-top");
require("./components/into-view");
require("./components/faq");

import Slider from "./objects/slider.js";

const sliders = document.querySelectorAll('.slider');

if (sliders.length > 0) {
    sliders.forEach((slider) => {
        const object = new Slider(slider);
        object.init();
    });
}

const interestInputs = document.querySelectorAll('input[name="interest"]');
const flexibleFields = document.querySelectorAll('.form__flexible');

if (interestInputs.length > 0) {
    interestInputs.forEach((input) => {
        input.addEventListener('change', () => {
            flexibleFields.forEach((field) => {
                field.classList.add('-hide');

                if(field.dataset.tab === input.value) {
                    field.classList.remove('-hide');
                }
            });
        });
    });
}