let header = document.querySelector('.header');
let scrollY = window.scrollY;

document.addEventListener('scroll', () => {
    if (window.scrollY > 50) {
        header.classList.add('-scrolled');
    } else {
        header.classList.remove('-scrolled');
    }
});
